const staging = "prod"
const live_app_url = "https://nd0874xbxj.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const dev_app_url = "https://owou50od12.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const live_app_url_second = "https://s0ybu4vmu9.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const dev_app_url_second = "https://9whgt8xbs3.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const live_app_url_third = "https://sxql70itni.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const dev_app_url_third = "https://9n8qfym8n7.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const live_close_url = "https://vfavieava6.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const dev_close_url = "https://d09gxrjx41.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const dev_sync_mysql_url = "https://bejwiqx4zh.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const live_sync_mysql_url = "https://uwqthxuul7.execute-api.us-east-1.amazonaws.com/" + staging + "/";

const api_lambda_invoke_url = "https://sw59m81532.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const dev_report_app_url = "https://dyzokxgprc.execute-api.us-east-1.amazonaws.com/" + staging + "/";
const live_report_app_url = "https://0qktfrgqd0.execute-api.us-east-1.amazonaws.com/" + staging + "/";


// prod/SyncMysqlWithDynamo
const close_account_url = staging == "prod" ? live_close_url : dev_close_url
const app_url = staging == "prod" ? live_app_url : dev_app_url
const app_report_url = staging == "prod" ? live_report_app_url : dev_report_app_url
const app_url_second = staging == "prod" ? live_app_url_second : dev_app_url_second
const app_url_third = staging == "prod" ? live_app_url_third : dev_app_url_third
const sync_mysql_url = staging == "prod" ? live_sync_mysql_url : dev_sync_mysql_url
const mobile_verification_url = staging == "prod" ?
    "https://xwsbwrc070.execute-api.us-east-1.amazonaws.com/prod/" :
    "https://o5j1kgugf3.execute-api.us-east-1.amazonaws.com/dev/";
const report_app_url= staging == "prod" ? live_report_app_url : dev_report_app_url

const social_share_url = "https://share.family.one/";
export default {
    varient:"/public",
    family_one_image_url: "https://d1z9u8bxwqauz3.cloudfront.net/images/",
    image_path: "https://d1z9u8bxwqauz3.cloudfront.net/images/",
    cloudflare_image_path: "https://imagedelivery.net/droh--rvkvo7IkO_o-KYsQ/compressImage/images/",
    phone_verify: mobile_verification_url + 'invokeLambdaPhoneVerify',
    sms_link_verify: mobile_verification_url + 'verifySmsLink',
    site_url: staging == "prod" ? "https://app.family.one/" : "https://d1mgodp60suff0.cloudfront.net/",
    family_one_url: staging == "prod" ? "https://app.family.one/" : "https://d1mgodp60suff0.cloudfront.net/",
    family_onetest_url: "https://app.family.one/home/homeTest/",
    social_share_url: social_share_url,
    session_manage: app_url + "manageSessionLog",
    update_manage_session_log: app_url + "UpdateSessionLog",
    update_user: app_url + "updateUserinfo",
    get_user: app_url + "getUserCognito",
    add_user_relation: app_url + "addRelativeDynamoDB",
    delete_user_relation: app_url + "deleteRelationDynamoDB",
    accept_decline_relation: app_url + "updateRelativeDynamoDB",
    get_relative_info: app_url + "getInvitationDetailByTempID",
    get_program: app_url + "getProgramDynamoDB",
    get_user_address_type: app_url_second + "getUserAddressType",
    get_profile_user: app_url_second + "getUserProfile",
    password_login: app_url + "checkPasswordCognito",
    send_login_link: app_url + "searchUserByphoneAndSendLoginLink",
    forget_email: app_url + "forgetEmailCognito",
    contest_entry: app_url + "invokeProgramOptinStep",
    sendSMTPMail: app_url + "sendSMTPMail",
    check_program_criteria: app_url + "checkProgramEntryCriteria",
    get_program_winner: app_url + 'getProgramWinners',
    get_winner_program_detail: app_url + "getWinnerProgramDetail",
    update_winner_status: app_url + "updateWinnerStatus",
    get_child_relative: app_url + "getUserChildAndRelative",
    get_setting_notification: app_url + "getNotificationList",
    get_urgent_notification: app_url + "GetUrgentNotification",
    cancel_freebie_entry: app_url + "deleteRecordFromEPC",
    wetransfer_api: app_url + "getWeTransferKey",
    insert_entry_in_perks: app_url + "InsertInPerksEntryLog",
    insert_in_notify_me: app_url + "insertNotifyMe",
    notification_uri: app_url_second + "GetAllNotificationForUser",
    validateToken: app_url_second + "ValidateLoginToken",
    verifyCode: app_url_second + "verifyCode",
    getProgramOptinOrPendingData: app_url_second + "getProgramOptinOrPendingData",
    update_urgent_notification: app_url_second + "updateUrgentNotification",
    insert_request_for_review: app_url_second + "InsertInReviewRequest",
    ValidateEmailForBounce: app_url_third + "CheckEmailBounce",
    delete_relation_in_mail: app_url_third + "DeleteRelation",
    check_relation_status: app_url_third + "CheckRelationStatus",
    session_update: app_url_second + "UpdateSession",
    updateReview: app_url_second + "updateReview",
    get_perks: app_url_second + "GetPerksList",
    insertWinnerReview: app_url_second + "insertWinnerReview",
    insert_in_program_notify_me: app_url_second + "InsertAndUpdateProgramNotifyMe",
    check_password_existence: app_url_second + "CheckPasswordExistence",
    adminCreateUser: app_url_second + "adminCreateUser",
    CallMigrateToPostgresSQL: app_url_second + "CallMigrateToPostgresSQL",
    adminSignupConfirm: app_url_second + "adminSignupConfirm",
    getDynamicContent: app_url_second + "GetDynamicContentWeb",
    insertQldb: app_url_second + 'insertQldb',
    send_reminder: app_url_second + 'SendReminderRecommend',
    activation_mail: app_url_second + 'activateMail',
    check_user_existence: app_url_second + 'CheckUserExistenceInOldNewSytsem',
    get_campaign_info: app_url_second + 'GetCampaignInfo',
    save_campaign_view: app_url_second + 'campaign-view',
    closeMyAccount: close_account_url + 'closeMyAccount',
    insertTermsInQldb: close_account_url + "InsertUserActivityInQLDB",
    phone_code: "+1",
    dynamic_content: {},
    program_detail: app_url_second + 'program-detail',
    SyncMysqlWithDynamo: sync_mysql_url + "SyncMysqlWithDynamo",
    changePasswordStatus: app_url_third + 'changePasswordStatus',
    save_feedback: app_url_third + 'saveFeedbackInMysql',
    LogInProgramEntry: app_url_third + 'LogInProgramEntry',
    LogInSurveyEntry: app_url_third + 'UpdateSurveyEntry',
    changeAlternativeEmail: app_url_third + 'changeAlternativeEmail',
    addExistingRelativeChildNotification: app_url_third + 'addExistingRelativeChildNotification',
    getPresignedUrl: app_url_third + 'getPresignedUrl',
    mturkApi: app_url_third + 'mturkApi',
    SendMailToSafariUser: app_url_third + 'SendMailToSafariUser',
    perkCouponUpdate: app_url_third + 'PerkCoupon',
    get_program_detail_s_o_n : app_url + 'getProgramDetail',
    callLambdaFunctionUser : sync_mysql_url + 'callLambdaFunctionUser',
    unsubscribe_user_email : sync_mysql_url + 'unsubscribeUserEmail',
    ai_AddressReport : app_report_url + 'ai_AddressReport',
    ai_AddressPlacesApi : app_report_url + 'ai_AddressPlacesApi',
    staging,
    InsertInErrorLogHTTP: "https://00529r5i8j.execute-api.us-east-1.amazonaws.com/prod/InsertInErrorLoghttp",
    facebook_setting_uri : app_url_second + 'facebookButtonDisabled',
    UpdateEmailVerifiedTrue : "https://mo5us0k6r2.execute-api.us-east-1.amazonaws.com/dev/UpdateEmailVerifiedTrue2",
    CheckUserExistenceAndSendOTP: app_url_third+"CheckUserExistenceAndSendOTP",
    AppRunnerFBCAPI: "https://idprqeqwgc.execute-api.us-east-1.amazonaws.com/dev/AppRunnerFBCAPI"
}