import functions from '../src/assets/functions/common_functions';

let ReactPixel = null;
export const initFacebookPixel = (pixelId) => {
  let event_timestamp = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
  if (typeof window !== 'undefined') {
    // Dynamically import the library only on the client
    import('react-facebook-pixel').then((module) => {
      ReactPixel = module.default;
      ReactPixel.init(pixelId);
      // Pass custom data with event_timestamp in the pageView call
      ReactPixel.pageView({ event_time: event_timestamp });
      trackEventViaConversionAPI("PageView", event_timestamp);
    });
  }
};

export const trackEvent = (event, data = {}) => {
  if (typeof window === 'undefined' || !ReactPixel) return;

  try {
    let event_timestamp = Math.floor(Date.now() / 1000);
    // Add event_time to data and track the event using ReactPixel
    data.currency = 'USD';
    data.value = 0.00;
    const eventData = {
      ...data,
      event_time: event_timestamp // Current time in UNIX timestamp
    };
    ReactPixel.track(event, eventData);
    trackEventViaConversionAPI(event, event_timestamp);   
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};
// Fetch the IP address
const getIpAddress = async () => {
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    // const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

async function trackEventViaConversionAPI(event, event_timestamp) {
  try{
    // Retrieve cookies and local storage data
    const _fbp = functions.getCookie('_fbp');
    const _fbc = functions.getCookie('_fbc');
    const event_source_url = window.location.href;
    const fb_login_id = functions.getCookie('facebook_id')

    // Construct final JSON payload
    const final_json = {
      fb_login_id: fb_login_id,
      user_agent : navigator.userAgent,
      ip_address: await getIpAddress(),
      event_source_url,
      event_name: event,
      event_timestamp: event_timestamp,
      fbp: _fbp,
      fbc: _fbc,
      auto_id: functions.getCookie('auto_id') ? functions.getCookie('auto_id') : localStorage.getItem("auto_id")
    };

    // Invoke the App Runner API with the constructed payload
    functions.invokeAppRunnerFBCAPI(final_json, (res) => {});
  }catch(e){
    console.log(e);
  }
}